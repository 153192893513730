.apps-container{
 
  min-height: 100vh;
  

}
@media (max-width:768px) {
  .apps-container{
    min-height: 100vh;
  }
}